<template>
  <div class="content">
    <modal :show="isModalShow" :showClose="false">
      <template slot="header">
        <h5 class="modal-title">{{ $t("confirm.delete.user") }}</h5>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="isModalShow = false">{{
          $t("cancel")
        }}</base-button>
        <base-button type="danger" @click="deleteItem">{{
          $t("delete")
        }}</base-button>
      </template>
    </modal>
    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="d-block col d-sm-flex justify-content-between pb-3">
              <div>
                <base-button
                    type="cancel"
                    @click="goBack()"
                >{{ $t("goBack") }}</base-button>
              </div>
              <div v-if="isAdmin">
                <base-button
                    v-if="id != null && isAdmin && !isSpinnerShow"
                    type="danger"
                    class="mr-2"
                    @click="isModalShow = true"
                >{{ $tc("delete") }}</base-button
                >
                <base-button
                    :disabled="isBtnDisabled"
                    native-type="submit"
                    type="success"
                    form="categoryForm"
                >{{ $t("save") }}</base-button
                >
              </div>
            </div>
            <div class="col d-flex justify-content-between">
              <div>
                <h1 v-if="id == null && isAdmin" class="card-title mb-0">
                  {{ $t(`routes.${this.$route.name}`) }}
                </h1>
                <h1 v-if="id != null && isAdmin" class="mb-0">
                  {{ user.name }}
                </h1>
              </div>
            </div>
          </template>
          <form
            id="categoryForm"
            class="pb-4"
            @change="disabledBtn(false)"
            @submit.prevent="manageItem()"
          >
            <hr />
            <div v-if="!isSpinnerShow" class="col py-4">
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input
                    max="255"
                    v-model="user.name"
                    :placeholder="$t('name')"
                    :label="`${$t('name')}*`"
                    required
                  ></base-input>
                </div>

              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input
                    inputType="email"
                    v-model="user.email"
                    :placeholder="$t('email')"
                    :label="`${$t('email')}*`"
                    required
                  ></base-input>
                </div>
                <div class="col-12 col-md-6">
                  <label class="control-label mt-4 mt-md-0">{{
                    $t("picture")
                  }}</label>
                  <div class="">
                    <image-upload
                      @change="onImageChange"
                      :src="user.picture"
                      :changeText="$t('change')"
                      :removeText="$t('delete')"
                      :select-text="$t('select.picture')"
                      altImage="service"
                    />
                  </div>
                </div>
              </div>
              <div v-if="id == null" class="row mt-5">
                <div class="col-12 col-md-6">
                  <base-input
                    inputType="password"
                    @keydown.space.prevent
                    v-model="user.password"
                    :placeholder="$t('password')"
                    :label="`${$t('password')}*`"
                    required
                  ></base-input>
                </div>
                <div class="col-12 col-md-6">
                  <base-input
                    inputType="password"
                    @keydown.space.prevent
                    v-model="user.passwordConfirm"
                    :placeholder="$t('passwordConfirm')"
                    :label="`${$t('passwordConfirm')}*`"
                    required
                  ></base-input>
                </div>
              </div>
            </div>
            <Spinner v-else></Spinner>
          </form>
        </card>
      </div>
    </div>

    <div v-if="id != null" class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="d-flex justify-content-between">
              <div>
                <h4 class="card-title mb-0">
                  {{ $t(`edit`) }} {{ $t(`password`).toLowerCase() }}
                </h4>
              </div>
            </div>
          </template>
          <form
            class="py-4"
            @change="disabledBtnPassword(false)"
            @submit.prevent="managePassword()"
          >
            <div class="d-block d-sm-flex justify-content-end" v-if="isAdmin">
              <base-button
                :disabled="isBtnDisabledPassword"
                native-type="submit"
                type="success"
                >{{ $t("save") }}</base-button
              >
            </div>
            <hr />
            <div class="row py-4">
              <div class="col-12 col-md-6">
                <base-input
                  inputType="password"
                  @keydown.space.prevent
                  v-model="user.password"
                  :placeholder="$t('password')"
                  :label="`${$t('password')}`"
                ></base-input>
              </div>
              <div class="col-12 col-md-6">
                <base-input
                  inputType="password"
                  @keydown.space.prevent
                  v-model="user.passwordConfirm"
                  :placeholder="$t('passwordConfirm')"
                  :label="`${$t('passwordConfirm')}`"
                ></base-input>
              </div>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseInput,
  Card,
  ImageUpload,
  Modal,
  Spinner,
} from "@/components/index";
import { mapActions, mapState } from "vuex";

export default {
  name: "UserForm",
  data: () => {
    return {
      id: null,
      roleId: 2,
      route: "/users",
      isBtnDisabled: true,
      isBtnDisabledPassword: true,
      user: {
        name: null,
        email: null,
        password: null,
        passwordConfirm: null,
        role: null,
        picture: null,
      },
      userPicture: null,
      isModalShow: false,
      isSpinnerShow: true,
    };
  },
  computed: {
    ...mapState(["isAdmin"]),
  },
  methods: {
    createItem() {
      return {
        name: this.user.name,
        email: this.user.email,
        role_id: this.roleId,
      };
    },
    createPassword() {
      let password = {
        password: this.user.password,
        password_confirmation: this.user.passwordConfirm,
      };

      if (
        (password.password === null ||
          password.password === "" ||
          password.password === undefined) &&
        (password.password_confirmation === null ||
          password.password_confirmation === "" ||
          password.password_confirmation === undefined)
      ) {
        if (this.id === null)
          this.$toast.error(this.$t("notifications.error.mandatoryPassword"));
        return;
      }

      if (password.password !== password.password_confirmation) {
        this.$toast.error(
          this.$t("notifications.error.passwordConfirmationNotMatch")
        );
        return;
      }
      return password;
    },
    manageItem() {
      const user = this.createItem();
      const password = this.createPassword();
      const userPassword = { ...user, ...password };
      if (this.id != null && this.isAdmin) {
        this.updateItem(user);
        return;
      }
      this.storeItem(userPassword);
    },
    managePassword() {
      const password = this.createPassword();
      if (this.id != null && this.isAdmin) {
        if (password) this.updatePassword(password);
      }
    },
    onImageChange(file) {
      this.userPicture = new FormData();
      this.userPicture.append("media", file);
    },
    async storeItem(user) {
      const response = await this.axios.post(this.route, user);
      if (response && response.data.status === "success") {
        this.disabledBtn(true);
        await this.addPicture(`${this.route}/${response.data.data.users.id}`);
        this.$router.push({ name: "userList" });
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async getItem() {
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        this.user = response.data.data.users;
        this.setRoleId(this.user.role);
        this.disabledBtn(true);
        this.isSpinnerShow = false;
      }
    },
    async updateItem(user) {
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.put(route, user);
      if (response && response.data.status === "success") {
        await this.addPicture(route);
        this.disabledBtn(true);
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async updatePassword(password) {
      const route = `${this.route}/${this.id}/password`;
      let response = await this.axios.put(route, password);
      if (response && response.data.status === "success") {
        this.user.password = "";
        this.user.passwordConfirm = "";
        this.disabledBtnPassword(true);
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async deleteItem() {
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.delete(route);

      this.isModalShow = false;
      if (response && response.data.status === "success") {
        this.$router.push({ name: "userList" });
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    setRoleId(role) {
      const roles = { Admin: 1, Manager: 2, Employee: 3, Customer: 4 };
      this.roleId = roles[role];
    },
    async addPicture(route) {
      if (this.userPicture !== null) {
        const routePicture = `${route}/picture`;
        return await this.axios.post(routePicture, this.userPicture);
      }
    },
    disabledBtn(disabled) {
      this.$store.dispatch('setHasChangeForm', !disabled );
      this.isBtnDisabled = disabled;
    },
    disabledBtnPassword(disabled) {
      this.$store.dispatch('setHasChangeForm', !disabled );
      this.isBtnDisabledPassword = disabled;
    },
    goBack(){
      this.$router.push({ name: 'userList' });
    },
    ...mapActions(["setHasChangeForm"])
  },
  beforeMount() {
    this.id = this.$route.params.id;
    if (this.id != null) {
      this.getItem();
    } else {
      this.isSpinnerShow = false;
    }
  },
  components: {
    Card,
    BaseButton,
    BaseInput,
    ImageUpload,
    Modal,
    Spinner,
  },
};
</script>
<style scoped>
.img-size {
  width: 250px;
  height: 250px;
  object-fit: cover;
}
</style>
